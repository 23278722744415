@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Bentham&display=swap");
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer components {
  .button-standard {
    @apply inline-flex w-full justify-center place-items-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-auto;
  }

  .button-submit {
    @apply inline-flex w-full justify-center place-items-center rounded-md bg-primary px-3 py-2 font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 hover:shadow-xl hover:opacity-80 sm:w-fit;
  }

  .button-search {
    @apply text-white bg-search tracking-widest p-2 lg:p-4 w-full;
  }
}